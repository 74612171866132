import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { REACT_BASE_PATH, Asset_url } from "../../../api";

const Spreedly = ({ grandTotal, setIsPaymentModalOpen, setPaymentStatus, setPaymentError, deliveryAddress, email, placedOrderDetails }) => {
    // State for first name and last name

    const [options, setOptions] = useState({
        first_name: '',
        last_name: '',
        month: '',
        year: ''
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (window.Spreedly) {
            // console.log("Spreedly script loaded successfully");

            window.Spreedly.init(process.env.REACT_APP_SPREEDLY_ENV_KEY, {
                numberEl: 'spreedly-number',
                cvvEl: 'spreedly-cvv',
            });

            window.Spreedly.on('ready', () => {
                console.log('Spreedly is ready');
                window.Spreedly.setFieldType('number', 'text');
                window.Spreedly.setFieldType('cvv', 'text');
                window.Spreedly.setPlaceholder('number', 'Credit Card Number');
                window.Spreedly.setPlaceholder('cvv', 'CVV');
                window.Spreedly.setNumberFormat('maskedFormat');
                window.Spreedly.setStyle('number', 'width: 96%; border-radius: 5px; border: 1px solid #4B0950; padding: 10px; outline: none; font-size: 16px;');
                window.Spreedly.setStyle('cvv', 'width: 93%; border-radius: 5px; border: 1px solid #4B0950; padding: 10px; outline: none; font-size: 16px;');
            });

            window.Spreedly.on('paymentMethod', async function (token, pmData) {
                try {
                    //Stripe
                    // const res = await axios.post(`${REACT_BASE_PATH}/payment/spreedly-stripe-payment`, {
                    //     paymentMethodToken: token,
                    //     amount: grandTotal.toFixed(2) * 100,
                    // })

                    // NMI
                    const billingInfo = {
                        address: deliveryAddress?.address_detail,
                        city: deliveryAddress?.city,
                        state: deliveryAddress?.state,
                        country: deliveryAddress?.country,
                        zip: deliveryAddress?.pin_code,
                    };

                    const shippingInfo = {
                        full_name: deliveryAddress?.full_name,
                        address: deliveryAddress?.address_detail,
                        city: deliveryAddress?.city,
                        state: deliveryAddress?.state,
                        country: deliveryAddress?.country,
                        zip: deliveryAddress?.pin_code,
                    };
                    const res = await axios.post(`${REACT_BASE_PATH}/payment/spreedly-nmi-payment`, {
                        paymentMethodToken: token,
                        amount: grandTotal.toFixed(2)*100,
                        billingInfo,
                        shippingInfo,
                        email: email

                    })

                    if (res.data.transaction.state === "succeeded") {
                        const updatePaymentStatus = await axios.post(`${REACT_BASE_PATH}/product/updatePaymentStatus`, {
                            paymentStatus:'success',
                            orderId: placedOrderDetails.order._id
                        })

                        if(updatePaymentStatus.data.success){
                            setPaymentStatus('success');
                            setTimeout(() => {
                                navigate(`/order-details/${placedOrderDetails.order._id}`);
                            }, 6000);
                        }
                    }
                } catch (error) {
                    console.error('Error:', error)
                    const updatePaymentStatus = await axios.post(`${REACT_BASE_PATH}/product/updatePaymentStatus`, {
                        paymentStatus:'failed',
                        orderId: placedOrderDetails.order._id
                    })
                    if(updatePaymentStatus.data.success){
                        setPaymentStatus('failed');
                    }
                    toast.error(error?.response?.data?.message || error?.response?.data?.error || 'Error in initiating payment')
                    setPaymentError(error?.response?.data?.message || error?.response?.data?.error || 'Error in initiating payment')
                }
            });

            window.Spreedly.on('errors', (errors) => {
                console.error('Spreedly errors:', errors);
                errors?.map((error) => {
                    toast.error(error.message)
                })
            });
        }
    }, []);

    const handlePayment = (event) => {
        event.preventDefault();
        setPaymentStatus('processing')
        window.Spreedly.tokenizeCreditCard(options)
    };

    return (
        <form id="payment-form" className='row m-0 text-start' onSubmit={handlePayment}>

            <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                <label for="first_name">First Name</label>
                <input type="text" placeholder="First Name" id="first_name" name="first_name" value={options.first_name} onChange={(e) => setOptions({ ...options, first_name: e.target.value })} /><br />
            </div>
            <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                <label for="last_name">Last Name</label>
                <input type="text" placeholder='Last Name' id="last_name" name="last_name" value={options.last_name} onChange={(e) => setOptions({ ...options, last_name: e.target.value })} /><br />
            </div>
            <div className="checkout__form-group">
                <label>Credit Card Number</label>
                <div id="spreedly-number"></div><br />
            </div>
            <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                <label>CVV</label>
                <div id="spreedly-cvv"></div><br />
            </div>
            <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                <label for="month">Expiration Date</label>
                <div className=' d-flex gap-2'>
                    <input type="text" placeholder='MM' style={{ width: '50%' }} id="month" name="month" maxlength="2" value={options.month} onChange={(e) => setOptions({ ...options, month: e.target.value })} />
                    <input type="text" id="year" placeholder='YYYY' style={{ width: "50%" }} name="year" maxlength="4" value={options.year} onChange={(e) => setOptions({ ...options, year: e.target.value })} /><br />
                </div>
            </div>

            {/* <input id="submit-button" type="submit" value="Pay Now" /> */}
            <div className='d-flex justify-content-center gap-5 mt-4'>
                <button type='submit' className="checkout__btn checkout__confirm-btn">
                    Continue
                </button>
                <button type="button" className="checkout__btn checkout__confirm-btn" onClick={() => setIsPaymentModalOpen(false)}>
                    Cancel
                </button>
            </div>

        </form >
    );
};

export default Spreedly;
