import React from 'react'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const TawkChat = () => {
    const propertyId = process.env.REACT_APP_TAWK_PROPERTYID
    const widgetId = process.env.REACT_APP_TAWK_WIDGETID

    // console.log(propertyId, widgetId)
    return (
        <>
            <TawkMessengerReact
                propertyId={propertyId}
                widgetId={widgetId}
            />
        </>
    )
}

export default TawkChat
