import React, { useContext, useEffect, useState } from 'react';
import './category.css';
import { FaCaretDown } from 'react-icons/fa';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import { CartContext } from '../cart/CartContext';
import Cookies from 'js-cookie'
import { REACT_BASE_PATH, Asset_url } from "../../../api";
import { toast } from 'react-toastify';

const CategoryProducts = () => {
  const [categoryInfo, setCategoryInfo] = useState({
    name: '',
    banner: ''
  })
  const [allSubCategories, setAllSubCategories] = useState([])

  const { categoryId } = useParams();

  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        const response = await axios.get(
          `${REACT_BASE_PATH}/product/category-subcategory`
        );
        if (response.data.success) {
          // console.log(response.data.data)
          const categories = response.data.data;

          const matchedCategory = categories.find(
            category => category._id === categoryId
          );

          if (matchedCategory) {
            setCategoryInfo({
              name: matchedCategory.category_name,
              banner: matchedCategory.category_image,
            })
            setAllSubCategories(matchedCategory.children);
          } else {
            // console.error("Category not found");
            toast.error("Category not found. Please try again.");
          }
        } else {
          console.error("Error fetching categories:", response.data.message);
          toast.error("An error occurred while fetching categories. Please try again.");
        }
      } catch (error) {
        // console.error("Error fetching categories:", error);
        toast.error(error.response?.data?.message || "Some error occured, please try again");
      }
    };

    fetchAllCategories();
  }, [categoryId]);

  return (
    <div className="category-product-page">
      <div className='container'>
        <div className="row my-3">
          <p>
            <b>Catalogue</b> {">"}{" "}
            {categoryInfo.name ? categoryInfo.name : ''}
          </p>
        </div>
        {categoryInfo.name && <h3 className='mb-5'>{categoryInfo.name} Subcategories</h3>}
        <div className="d-flex flex-wrap justify-content-center gap-5">
          {allSubCategories.length > 0 ? (
            allSubCategories.map((subcategory, index) => (
              <NavLink to={`/subcategory/${subcategory._id}`}>
                <div className="subcategory-card" style={{width:'100px'}}>
                  <img
                    src={`${Asset_url}${subcategory.category_image}`}
                    alt={subcategory.category_name}
                    className="img-fluid rounded-circle"
                  />
                  <p className="mt-2 text-center">{subcategory.category_name}</p>
                </div>
                </NavLink>
            ))
          ) : (
            <p>There are no sub-categories in this category</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryProducts;
