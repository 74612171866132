import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../header/Footer";
import { REACT_BASE_PATH } from "../../api";
import { useAuth } from "./AuthContext";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
// import('../../components/WebPanel/WebPanelRoutes.css');

const VendorLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [IsOtp, setIsOtp] = useState(false)
  const [VendorId, setVendorId] = useState(null)
  const [Otp, setOtp] = useState('')
  const { login } = useAuth();

  const submitData = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${REACT_BASE_PATH}/auth/vendor/login`,
        {
          vendor: {
            vendor_contact: email,
            password: password,
          },
        }
      );
      localStorage.setItem("vendor", JSON.stringify(response?.data));
      localStorage.setItem("user", JSON.stringify(response?.data));
      localStorage.setItem("step_completed", JSON.stringify(response?.data?.signup_stage));

      if (response?.data.role !== 'admin') {
        if (!response?.data.is_kyc_approved || !response?.data.is_kyb_approved) {
          navigate("/vendor-registration");
        } else {
          login(response.data);
          navigate("/vendor");
          window.location.reload()
          console.log(response.data, "Login Successfully");
        }
      } else {
        login(response.data);
        navigate("/admin");
        window.location.reload()
      }

    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "Your account is not authorized otp is send to your registered Eamil ") {
          setIsOtp(true)
          setVendorId(error.response.data.vendor_id)
          console.log(error.response.data.message);
        }

      } else {
        console.log("An error occurred during login:", error.message);
      }
    }
  };

  const submitOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${REACT_BASE_PATH}/user/register`,
        {
         
        }
      );
      if (response) {
        setOtp('')
        setEmail('')
        setPassword('')
        setIsOtp(false);
      }

    } catch (error) {
      if (error.response) {
        console.log('======', error.response.data.message);
      } else {
        console.log("An error occurred during login:", error.message);
      }
    }
  }

  return (
    <>
      <section>
        <div className="container">
          {!IsOtp ? <div className="row mb-5">
            <div className="signin-box">
              <div className="signin-box1">
                <h4 className="text-center mt-5">Krizaar.com</h4>
                <div className="sign-form">
                  <h5 className="mb-4">Sign In</h5>
                  <form onSubmit={submitData}>
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="email-input"
                      placeholder=""
                      required
                    />
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="email-input"
                      placeholder=""
                      required
                    />
                    <br />
                    <br />
                    <div className="sbtn-box">
                      <button type="submit" className="sub-btn">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <hr className="mt-4" />
            <div className="amazon-box">
              <span className="amazon">or</span>
            </div>
            <div className="create-acc-box">
              <NavLink to="/vendor-registration">
                <button className="create-acc">
                  Dont have an account, Sign Up
                </button>
              </NavLink>
            </div>
          </div>
            :
            <div className="row mb-5">
              <div className="signin-box">
                <div className="signin-box1">
                  <h4 className="text-center mt-5">Krizaar.com</h4>
                  <div className="sign-form">
                    <h5 className="mb-4">Verify Your OTP</h5>
                    <form onSubmit={submitOTP}>
                      <label htmlFor="email">OTP</label>
                      <input
                        type="text"
                        id="otp"
                        name="otp"
                        value={Otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="email-input"
                        placeholder="enter otp"
                        required
                      />
                      <br />
                      <br />
                      <div className="sbtn-box">
                        <button type="submit" className="sub-btn">
                          Verify OTP
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </section>
    </>
  );
};

export default VendorLogin;
