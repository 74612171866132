import React, { useEffect } from 'react';

const DownloadApp = () => {
  useEffect(() => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.app.krizaar';
  }, []);

  return (
    <div>
      Redirecting you to your play store...
    </div>
  );
};

export default DownloadApp;
