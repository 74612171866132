import React, { useContext, useEffect, useState } from "react";
import product1 from "../img/product1.jpeg";
import product6 from "../img/product5.jpeg";
import icon1 from "../img/icon/heart.png";
import icon2 from "../img/icon/exchange.png";
import icon3 from "../img/icon/cart-outline.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../components/WebPanel/cart/CartContext";
import Cookies from 'js-cookie'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import { REACT_BASE_PATH, Asset_url } from "../api";
import { toast } from "react-toastify";

const CategorySlider = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [babyProducts, setBabyProducts] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);

  useEffect(() => {
    const fetchbabyProducts = async () => {
      try {
        const response = await axios.post(`${REACT_BASE_PATH}/product/category/product`, {
          category_id: '66c5e92e3e180352a9d01587',
          level: 0,
          page: 1,
          pagesize: 100
        })
        // console.log(response.data.data)
        const subcategories = [
          "All",
          ...new Set(response.data.data.map((item) => item.category_details_level1.category_name)),
        ];
        setBabyProducts(response.data.data)
        setAllSubCategories(subcategories)
      } catch (e) {
        console.log("Error in fetching category products: ", e.message)
      }
    };
    fetchbabyProducts();
  }, [])

  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(new Set())

  const { state, dispatch } = useContext(CartContext);


  const onSingleProduct = (productId) => {
    navigate(`/singleproduct/${productId}`);
  };

  const handleAddToCart = async (product) => {

    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    const productInfo = {
      id: product._id,
      image: product.product_banner_image,
      name: product.product_name,
      price: product.price,
      offer_price: product.offer_price,
      seller: product.vendor_details,
      quantity: 1,
    }

    const isItemAlreadyInCart = state.cart.some(item => item.id === product._id);

    if (isItemAlreadyInCart) {
      toast.warning("Product is already in the cart!");
      return;
    }

    if (!accessToken || !userId) {
      dispatch({ type: 'ADD_TO_CART', payload: productInfo });
      toast.success("Product added to the cart!");
    } else {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/cart/user/add/product/cart`,
          {
            id: userId,
            product_id: product._id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          dispatch({ type: 'ADD_TO_CART', payload: productInfo });
          toast.success("Product added to the cart!");
        } else {
          toast.error("Failed to add product to the cart");
        }
      } catch (error) {
        console.error("Error adding product to the cart:", error);
              toast.error(error.response?.data?.message || "Some error occured, please try again");

      }
    }
  }

  const handleAddToWishlist = async (productId) => {
    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    if (!accessToken || !userId) {
      navigate("/signin");
      return;
    }

    if (wishlist.has(productId)) {
      try {
        const response = await axios.post(
          `${REACT_BASE_PATH}/product/wishlist/delete`,
          {
            userId: userId,
            productIds: [productId],
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.success) {
          toast.success("Product removed from wishlist successfully!");
          setWishlist((prevWishlist) => {
            const newWishlist = new Set(prevWishlist);
            newWishlist.delete(productId);
            return new Set(newWishlist);
          });
          return;
        } else {
          toast.error("Failed to remove product from wishlist.");
          return;
        }
      } catch (error) {
        console.error("Error removing product from wishlist:", error);
              toast.error(error.response?.data?.message || "Some error occured, please try again");

        return;
      }
    }

    try {
      const response = await axios.post(
        `${REACT_BASE_PATH}/product/wishlist/add`,
        {
          userId: userId,
          productId: productId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.success) {
        toast.success("Product added to wishlist successfully!");
        setWishlist((prevWishlist) => new Set(prevWishlist).add(productId));
      } else {
        toast.error("Failed to add product to wishlist.");
      }
    } catch (error) {
      console.error("Error adding product to wishlist:", error);
            toast.error(error.response?.data?.message || "Some error occured, please try again");

    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      const userId = localStorage.getItem("userId");
      const accessToken = Cookies.get('krizaar-user');

      if (!userId || !accessToken) {
        return;
      }

      try {
        const response = await axios.get(
          `${REACT_BASE_PATH}/product/wishlist/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data && response.data.data) {
          const wishlistProductIds = response.data.data.map((item) => item._id);
          setWishlist(new Set(wishlistProductIds));
        }
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    };
    fetchWishlist();
  }, [])

  const filteredProducts = activeTab === 'All'
    ? babyProducts
    : babyProducts.filter(
      (product) => product.category_details_level1.category_name === activeTab
    );



  return (
    <>
      <section>
        <div className="container-fluid prod-section">
          <div className="row">
            <div className="heading-box">
              <h3 className="prod-heading">Baby</h3>
              <span className="cate-btn-2">
                {allSubCategories.map(
                  (category) => (
                    <span
                      key={category}
                      className={`cate-btn ${activeTab === category ? "active" : ""
                        }`}
                      onClick={() => setActiveTab(category)}
                    >
                      {category}
                    </span>
                  )
                )}
              </span>
            </div>
            <hr />
          </div>
          <div className="container">
          {filteredProducts.length > 0 ? (
              <Swiper
                spaceBetween={16}
                slidesPerView={4}
                loop={true}
                navigation
                pagination={{ clickable: true }}
                className="pb-5"
                breakpoints={{
                  320: { 
                    slidesPerView: 1,
                    spaceBetween: 16,
                  },
                  576: { 
                    slidesPerView: 2,
                    spaceBetween: 16,
                  },
                  768: { 
                    slidesPerView: 3,
                    spaceBetween: 16,
                  },
                  992: { 
                    slidesPerView: 4,
                    spaceBetween: 16,
                  },
                  1200: { 
                    slidesPerView: 4,
                    spaceBetween: 16,
                  },
                }}
              >
                {filteredProducts.map((product) => (
                  <SwiperSlide key={product._id} style={{width: '100%'}}>
                    <div className="product-box-1" style={{width: '100%', height: '25rem'}}>
                      <div
                        className="product-content"
                        onClick={() => onSingleProduct(product._id)}
                      >
                        <img
                          src={product?.product_banner_image?.startsWith('http') ? product.product_banner_image : `${Asset_url}${product.product_banner_image}`}
                          alt={product.product_name}
                          className="prod-img img-fluid"
                        />
                        <p className="product-title">{product.product_name.length>40 ? product.product_name.slice(0,40) + '.....' : product.product_name}</p>
                        <span>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </span>
                        <p className="rate">
                          {product.offer_price ? `$${product.offer_price}` : product.price ? `$${product.price}` : "Price not available"}
                        </p>
                        <div className="icons">
                          <i
                            className="fas fa-shopping-cart cart-icon"
                            onClick={(e) => {
                              // e.preventDefault();
                              // e.stopPropagation();
                              // handleAddToCart(product);
                            }}
                          ></i>
                          <i
                            className="fas fa-heart wishlist-icon"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddToWishlist(product._id);
                            }}
                          ></i>
                          <i
                            className="fa fa-eye eye-icon"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <p className="pb-5">There are not any products in the selected subcategory in Baby Products</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CategorySlider;
