import React from 'react'
import CommonOtherLinkComponent from './krizaar_pay/CommonOtherLinkComponent'

const KrizaarAffluence = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Built for Influencers and Affiliates',
      content: 'Whether you’re an influencer or an affiliate, Affluence provides the tools you need to succeed. Our platform allows you to choose the products that resonate with you, generate custom links, and share them across your social networks.',
      image: '/images/affluence/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'Instant Earnings',
      content: "Every sale generated through your link is instantly credited to your wallet.",
      image: '/images/affluence/pic3.jpg'
    },
    {
      id: 3,
      subtitle: 'Instant Payments',
      content: 'Payments are deposited instantly but held for 15 days in line with our refund policy. Once the hold period ends, you can transfer or redeem them, or use them for purchases on the platform.',
      image: '/images/affluence/pic4.jpg'
    },
    {
      id: 4,
      subtitle: 'Influencers, Get Rewarded!',
      content: 'If you’re an influencer with over 5,000 followers, Affluence offers even more. You can request product samples from participating suppliers directly through the platform. Build authentic, engaging content with real product experiences, while earning at the same time.',
      image: '/images/affluence/pic5.jpg'
    },
  ]

  const heroSectionItems = {
    title: 'Welcome to Affluence',
    subtitle: 'Redefining Affiliate and Influencer Marketing',
    content2: "Affluence is the world’s first decentralized affiliate and influencer marketing platform, powered by blockchain technology. Designed to promote brands on a global scale, Affluence is where influencers and affiliates come together to create, share, and earn—securely and seamlessly.",
    image: '/images/affluence/pic1.jpg'
  }

  const middleBannerContent = {
    title: "Social Workforce",
    subtitle: "Join the New Wave of Marketing",
    description: 'The Social Workforce is a revolutionary new way for affiliates and influencers to engage with brands. Our daily task board offers opportunities to promote brands globally, driving engagement across social platforms',
    steps: [
      {
        title: `Targeted Social Tasks`,
        description: `Perform tactical tasks designed to increase engagement, visibility, and brand loyalty.`,
      },
      {
        title: `Boost Your Earnings`,
        description: `Each task completed brings you closer to earning more, making the social workforce a highly productive space for influencers, affiliates, and brands alike.`,
      }
    ],
  };

  return (
    <>
      <CommonOtherLinkComponent title="Why Choose Affluence?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={middleBannerContent} />
    </>
  )
}

export default KrizaarAffluence
