import React from "react";
import logo from "../img/email.png";
import logo2 from "../img/hone.png";
import logo3 from "../img/heart.png";
import logo4 from "../img/application.png";
import logo5 from "../img/support.png";
import logo6 from "../img/shop.png";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <div className="header1">

      <header className="header px-3 py-2">
        <div className="contact-info">
          <span className="email">
            <NavLink to="mailto:info@krizaar.com" className="header1-icon">
              <img src='/images/icons/email.png' alt="info" className="icon" /> info@krizaar.com
            </NavLink>
          </span>
          <span className="phone">
            <NavLink to="tel:+18885749227" className="header1-icon">
              <img src='/images/icons/phone.png' alt="info" className="icon" /> 1888 KRIZAAR (1 888-574-9227)
            </NavLink>
          </span>
        </div>
        <div className="nav-links header-righttop">
          <NavLink to="/whishlist" className="nav-link header1-icon">
            <img src='/images/icons/wishlist.png' alt="info" className="icon" /> Wishlist
          </NavLink>
          <NavLink to="/download-app" className="nav-link header1-icon">
            <img src='/images/icons/download app.png' alt="info" className="icon" /> Download App
          </NavLink>
          <NavLink to="/contactus" className="nav-link header1-icon">
            <img src='/images/icons/support and service.png' alt="info" className="icon" /> Customer Services
          </NavLink>
          <NavLink to="/about-us" className="nav-link last header1-icon">
            <img src='/images/icons/about us.png' alt="info" className="icon" /> About Us
          </NavLink>
        </div>
      </header>
    </div>
  );
};

export default Header;
