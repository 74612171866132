import React, { useContext, useEffect } from 'react'
import Header from "../../componenth/header/Header";
import Hero from "../../componenth/common/Hero";
import Productslide from "../../home/Productslide";
import Slider from "../../home/Slider";
import Footer from "../../componenth/header/Footer";
import Trend from "../../home/Trend";
import EndingSoon from "../../home/Ending";
import CategorySlider from "../../home/CategorySlider";
import WomenFashion from "../../home/WomenFashion";
import LoveKrizaa from "../../home/LoveKrizaa";
import Explore from "../../home/Explore";
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CheckoutContext } from './checkout/CheckoutContext';
import RecommendedProducts from '../../home/RecommendedProducts';
import ProductsLessThan500 from '../../home/ProductsLessThan500';
import BestQuality from '../../home/BestQuality';
// import "../../webpanel.css";

const Home = () => {
  useEffect(() => {
    //import('./WebPanelRoutes.css');
  }, [])

  const {state, dispatch} = useContext(CheckoutContext);
  useEffect(() => {
    dispatch({ type: 'CLEAR_CHECKOUT_ITEMS' });
  }, [])

  return (
    <>
      <Hero />
      <RecommendedProducts/>
      <EndingSoon />
      <BestQuality/>
      <Explore />
      <Productslide />
      <Slider />
      <ProductsLessThan500/>
      <CategorySlider />
      <LoveKrizaa />
      <WomenFashion />
      <Trend />
    </>
  );
};

export default Home;
