import React, { useState } from "react";
import Header from "../header/Header";
import Footer from "../header/Footer";
import toast from "react-hot-toast";
import { _getProfile, BASE_URL_AFFILATE } from "../../url";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

function AffilateLogin() {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const getVendorDetails = async (id,token) => {
    try {
        let payload = {
            "id": id
        }
        const response = await axios.post(_getProfile, payload,{
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });
        console.log("response.data?.ladger",response.data)
        const d = response.data.data;
        if(d?.kyc_status === "approved" || d?.isKyc) {
          toast.success("You have Logged in as affiliate");
          navigate("/affliateDashboard", {replace : true});
        } else if (d?.kyc_status === "pending") {
           toast.error("You KYC is in pending, you can't not login yet...");
        } else if(d?.kyc_status === "rejected") {
          toast.error(`You KYC has been rejected due to ${d?.kyc_remarks}`);
          navigate("/affilate_register", {replace : true});
        } else {
          toast.error("You need to signup again");
        }
    } catch (error) {
        console.error("Error fetching category:", error);
    }
}

  // Handle form input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    // Validate input
    if (id === "email") {
      setErrors({
        ...errors,
        email: value.includes("@") ? "" : "Please enter a valid email address",
      });
    }
    if (id === "password") {
      setErrors({
        ...errors,
        password:
          value.length >= 6
            ? ""
            : "Password must be at least 6 characters long",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setLoading(true);

    // Check for validation errors
    if (
      !formData.email ||
      errors.email ||
      !formData.password ||
      errors.password
    ) {
      setLoading(false);
      return alert("Please fix the errors in the form");
    }

    try {
      // Navigate after successful submission

      const response = await axios.post(
        `${BASE_URL_AFFILATE}/user/login`,
        formData
      );
      console.log("response>>>", response);
      const { responseMessage } = response;
      if ("accessToken" in response.data) {
        localStorage.setItem("affiliate", JSON.stringify(response.data));
       
        setFormData({ email: "", password: "" });
        getVendorDetails(response?.data?.userid,response?.data?.accessToken)
        // navigate("/affliateDashboard", {replace : true});
      } 
    } catch (error) {
      console.log("Error submitting form:", error);
      toast.error(error.response?.data.message || "Network Error");
    } finally {
      setLoading(false);
    }
  };
  return (
    <section>
          {loading && <LinearProgress color="secondary" />}
    
      <div className="signin-box">
        <div className="signin-box1 mb-5">
          <h4 className="text-center mt-5">Krizaar.com</h4>
          <div className="sign-form">
            <h5 className="mb-4">Affiliate Login</h5>
            <form onSubmit={handleSubmit}>
              <div className="space-y-1">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="email-input"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div className="space-y-1">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="email-input"
                />
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>
              <div className="sbtn-box mt-4">
                <button type="submit" className="sub-btn">
                  Sign In
                </button>
              </div>
            </form>
            <Link to="/affilate_register">
              <span className="text-center  text-white">
                Dont have an account, Sign Up
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AffilateLogin;
