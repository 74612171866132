import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import boy from "../img/boy.png";
import watch from "../img/watch.png";
import shoes from "../img/shoes1.png";
import bag from "../img/bags.png";
import bottel from "../img/bottel.png";
import contain from "../img/contain.png";
import vegitables from "../img/vegitables.png";
import almira from "../img/almira.png";
import wiper from "../img/wiper.png";
import accessories from "../img/accessories.png";
import hammer from "../img/hammer.png";
import extension from "../img/board.png";
import "./home.css";
import axios from "axios";
import { REACT_BASE_PATH, Asset_url } from "../api";
import { NavLink } from "react-router-dom";

const Explore = () => {
  const [menProducts, setMenProducts] = useState([]);
  const [womenProducts, setWomenProducts] = useState([]);
  const [kidsProducts, setKidsProducts] = useState([]);
  const petCategoryId = '67483ee7c144654bbb892c6a'
  const kitchenCategoryId = '67484095c144654bbb892cf6'
  const holidayCategoryId = '67483f07c144654bbb892c6e'


  useEffect(() => {
    const fetchPetProduccts = async () => {
      try {
        const response = await axios.post(`${REACT_BASE_PATH}/product/category/product`, {
          category_id: petCategoryId,
          level: 0,
          page: 1,
          pagesize: 100
        })
        // console.log(response.data.data)
        setMenProducts(response.data.data);
      } catch (e) {
        console.log("Error in fetching category products: ", e.message)
      }
    };
    const fetchKitchenProducts = async () => {
      try {
        const response = await axios.post(`${REACT_BASE_PATH}/product/category/product`, {
          category_id: kitchenCategoryId,
          level: 0,
          page: 1,
          pagesize: 100
        })
        setWomenProducts(response.data.data);
      } catch (e) {
        console.log("Error in fetching category products: ", e.message)
      }
    };
    const fetchHolidayProducts = async () => {
      try {
        const response = await axios.post(`${REACT_BASE_PATH}/product/category/product`, {
          category_id: holidayCategoryId,
          level: 0,
          page: 1,
          pagesize: 100
        })
        setKidsProducts(response.data.data);
      } catch (e) {
        console.log("Error in fetching category products: ", e.message)
      }
    };
    fetchPetProduccts();
    fetchKitchenProducts();
    fetchHolidayProducts();
  }, [])
  return (
    <>
      <section>
        <div className="container-fluid my-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="men-style-box">
                <h5>
                  <b>
                    Perfect Picks for Pets | Explore the Best
                  </b>
                </h5>
                <div className="row">
                  {
                    menProducts.slice(0,4).map((product) => (
                      <div className="col-lg-6 prod-box">
                        <img src={product?.product_banner_image?.startsWith('http') ? product.product_banner_image : `${Asset_url}${product.product_banner_image}`} alt={product.product_name} className="men-style" />
                        <p>{product.product_name.length>40 ? product.product_name.slice(0,40) + '.....' : product.product_name}</p>
                      </div>
                    ))
                  }
                </div>
                <NavLink className='text-dark text-decoration-underline' to={`/category/${petCategoryId}`}>Explore all</NavLink>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="men-style-box">
                <h5>
                  <b>Essentials for Kitchens | Explore Krizaar</b>
                </h5>
                <div className="row">
                  {
                    womenProducts.slice(0,4).map((product) => (
                      <div className="col-lg-6 prod-box">
                        <img src={product?.product_banner_image?.startsWith('http') ? product.product_banner_image : `${Asset_url}${product.product_banner_image}`} alt={product.product_name} className="men-style" />
                        <p>{product.product_name.length>40 ? product.product_name.slice(0,40) + '.....' : product.product_name}</p>
                      </div>
                    ))
                  }
                </div>
                <NavLink className='text-dark text-decoration-underline' to={`/category/${kitchenCategoryId}`}>See More</NavLink>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="men-style-box">
                <h5>
                  <b>Perfect Holiday Deals | Must Explore</b>
                </h5>
                <div className="row">
                  {
                    kidsProducts.slice(0,4).map((product) => (
                      <div className="col-lg-6 prod-box">
                        <img src={product?.product_banner_image?.startsWith('http') ? product.product_banner_image : `${Asset_url}${product.product_banner_image}`} alt={product.product_name} className="men-style" />
                        <p>{product.product_name.length>40 ? product.product_name.slice(0,40) + '.....' : product.product_name}</p>
                      </div>
                    ))
                  }
                </div>
                <NavLink className='text-dark text-decoration-underline' to={`/category/${holidayCategoryId}`}>See More</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Explore;
