import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import ContactForm from "../../componenth/common/ContactForm";

const ContactUs = () => {
  useEffect(() => {
    //import('./WebPanelRoutes.css');
  }, [])
  return (
    <>
      <section className="contact-section">
        <div className="container">
          <div className="row my-5">
            <div className="col-lg-6">
              <h3 className="contact-heading">Get In Touch !</h3>
              <div className="contact-box mt-3 mb-5">
                <div>
                  <i className="fas fa-map-marker-alt contact-map"></i>
                </div>
                <div className="ms-3">
                  700 Rosemary Ave, Ste 204
                  <br />
                  West Palm Beach. Fl 33401
                </div>
              </div>
              <div className="contact-box my-5">
                <div>
                  <i className="fa fa-mobile-phone contact-map"></i>
                </div>
                <div className="ms-3">1888 KRIZAAR (1 888-574-9227)</div>
              </div>
              <div className="contact-box my-5">
                <div>
                  <i className="fa fa-envelope contact-map"></i>
                </div>
                <div className="ms-3">info@krizaar.com</div>
              </div>
            </div>
            <div className="col-lg-6">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      <section className="contact-mapsection">
        <div className="container-fluid">
          <div style={{ width: "100%", height: "500px" }}>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.166242861195!2d-80.05959542598988!3d26.707135568897566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d8d66091d04d83%3A0x94de604fb17845a9!2s700%20S%20Rosemary%20Ave%20%23204%2C%20West%20Palm%20Beach%2C%20FL%2033401%2C%20USA!5e0!3m2!1sen!2sin!4v1718290397076!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <iframe
              title="Google Map of Federation Square"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.166242861195!2d-80.05959542598988!3d26.707135568897566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d8d66091d04d83%3A0x94de604fb17845a9!2s700%20S%20Rosemary%20Ave%20%23204%2C%20West%20Palm%20Beach%2C%20FL%2033401%2C%20USA!5e0!3m2!1sen!2sin!4v1718290397076!5m2!1sen!2sin"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
