import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { _getProfile } from '../../url';
import axios from 'axios';
import {getAffilateAccessToken, getAffilateUserId } from '../../utills';


function Wallet() {
  const [Vendordata, setVendordata] = useState([])
  const [Updatetype, setUpdatetype] = useState('');
  const [ladger, setLadger] = useState([])
  const [lifeTimeEar, setEach] = useState(0);
 
  useEffect(() => {
    getVendorDetails();
  }, [])


  const getVendorDetails = async () => {
      try {
          let payload = {
              "id": getAffilateUserId()
          }
          const response = await axios.post(_getProfile, payload,{
              headers: {
                'Authorization': `Bearer ${getAffilateAccessToken()}`
              }
          });
          // console.log("response.data?.ladger",response.data)
          if (response.data.data) {
              setVendordata(response.data?.data);
              setLadger(response.data?.ledger);
          }
      } catch (error) {
          console.error("Error fetching category:", error);
      }
  }


  useEffect(() => {
     console.log(ladger)
     if(ladger) {
      //  debugger;
        let data = ladger;
        data = data?.map(d => d?.amount);
        const earning = data?.reduce((acc,curr)=> {
           return acc+curr;
        }, 0);
        setEach(earning)
     }
  },[ladger]);


return (
  <>
     <section className="custom-cart-panel mb-3">
      <div className="container-fluid">
        <div className="row">
          <div className="vendor-top">
            <h5 className="title-v">Dashboard</h5>
            <div className="button-right-side">
              {/* <div className="addBrands btn-theam p-2">
                 wallet - {userInfo?.walletAmount}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
     <section className="custom-cart-panel mb-3">
      <div className="container-fluid">
        <div className="row">
        <Box
          sx={{
              width : '80%',
              margin : 'auto',
              p: 2,
              borderRadius: 2,
              bgcolor: 'background.default',
              display: 'grid',
              gridTemplateColumns: { md: '1fr 1fr' },
              gap: 2,
          }}
          >
          <div className="earning-card">
              <p>Current Earnings</p>
              <h2>${Vendordata?.walletAmount || '0.00'}</h2>
          </div>
          <div className="earning-card">
              <p>Life Time Earnings</p>
              <h2>${lifeTimeEar} .00</h2>
          </div>
          </Box>
        </div>
      </div>
    </section>
  </>
)
}

export default Wallet